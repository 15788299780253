export enum WEBVIEW_PATHS {
  FITNESS_PAYMENTS = "fitness/payment-methods",
  RENT = "/rent",
  CHECKLIST = "checklist",
  MAINTENANCE = "maintenance",
  EVENTS = "/events",
  INSPECTION_CHECKLIST = "/inspection",
  PROFILE = "/profile",
  UTILITIES = "/utilities",
  PAYMENT_INFORMATION = "/payment",
  SCHEDULE_MOVE_IN = "/schedule",
  CHECKOUT = "/cart",
  COMMUNITY_SURVEY = "/checklist/community-survey",
  INSURANCE_QUOTE = "/insurance/quote",
  INSURANCE_QUOTE_CURRENT = "/insurance-current/quote",
  INSURANCE_PROOF_CURRENT = "/insurance-current/proof",
  INSURANCE_PAYMENT_CURRENT = "/insurance-current/payment",
  INFO_DESK = "/page/",
  INSURANCE_MARKETPLACE = "/insurance-marketplace",
}
