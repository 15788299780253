export interface Building {
  clientDescription?: string;
  clientId: number;
  externalId: string;
  hasRunnerService: boolean;
  id: number;
  isDeepIntegrated: boolean;
  leaseStatusId?: boolean;
  name: string;
  bannerUrl: string | null;
  serviceTypes?: BuildingServiceType[];
  logoUrl: string | null;
  sortOrder: number;
  timezone: string;
  erpTypeId: ERPType | null;
}

export enum ERPType {
  Yardi,
  RealPage,
  Flats,
  RawAppfolio,
  RawRealPage,
  Entrata,
  RawLivly,
  Manual,
  ResMan,
  SuperProperty = 11,
  AppFolio
}

export interface BuildingServiceType {
  enabled: boolean;
  metaData?: string;
  externalMetaData?: string;
  propertyLivlyServiceId: number;
  serviceName: string;
  serviceType: number;
  serviceProviderType: ServiceProviderType;
  sortOrder: number;
  url?: string;
  displayName?: string;
  permissionAccessLevel: PermissionAccessLevelEnum;
}

export enum PermissionAccessLevelEnum {
  NoAccess = "NoAccess",
  FullAccess = "FullAccess",
  ReadOnly = "ReadOnly"
}

export enum ServiceProviderType {
  Livly = 0,
  External = 1,
  FlatsLife = 2,
  RealPage = 3,
  ClickPay
}

export enum ServiceTypeEnum {
  Rent = 1,
  RentersInsurance = 2,
  Packages = 3,
  Essentials = 4,
  Storage = 5,
  Rubbish = 6,
  Services = 7,
  Maintenance = 8,
  Urban = 9,
  ScheduleMoveIn = 10,
  MarketPlace = 11,
  Utility = 12,
  LivlyLocks = 13,
  Amenities = 14,
  MyGuests = 16,
  InfoDesk = 18,
  Events = 21,
  DelegateUsers = 26,
  CommFeed = 17,
  Messaging = 31,
  Fitness = 35,
  CommSurvey = 37,
  ResidentFeedback = 33,
  Pets = 40,
  Vehicles = 41,
  ResidentPolls = 44
}
