import { Modal } from "@/components/Dialog";
import { Spinner } from "@/components/Spinner";
import useLivlyUser from "@/context/UserProvider";
import { BaseLivlyApiResponse } from "@/types/Base";
import {
  defineFeedbackFormOptions,
  Feedback,
  FeedbackRequest
} from "@/components/Feedback/feedback";

import { BASE_API_URL } from "@/utils/constants";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import FeedbackForm from "@/components/Feedback/FeedbackForm";
import useIsAppFolioMaintenanceProperty from "@/hooks/useIsAppFolioMaintenanceProperty";

async function getFeedbackItem(id: string) {
  const result = await axios.get<BaseLivlyApiResponse<Feedback>>(
    `${BASE_API_URL}/resident/feedback/${id}`
  );

  return result.data.Data;
}

async function updateFeedbackItem(feedback: FeedbackRequest) {
  const result = await axios.put<BaseLivlyApiResponse<Feedback>>(
    `${BASE_API_URL}/resident/feedback/${feedback.feedbackId}`,
    feedback
  );

  return result.data.Data;
}

export default function EditFeedbackAndReviewsPage() {
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams<{ reviewId: string }>();
  const [feedback, setFeedback] = useState<FeedbackRequest | null>(null);
  const updateFeedbackItemMutation = useMutation(
    (updatedFeedback: FeedbackRequest) => updateFeedbackItem(updatedFeedback)
  );
  const hideCategory = useIsAppFolioMaintenanceProperty();

  const { data, isLoading } = useQuery({
    queryKey: ["my-feedback", "item", params.reviewId],
    queryFn: () => getFeedbackItem(params.reviewId!)
  });

  useEffect(() => {
    if (data) {
      setFeedback({
        feedbackId: data.residentFeedbackId,
        rating: data.rating,
        comment: data.comment,
        feedbackItems: data.feedbackItems
      });
    }
  }, [data]);

  const onSetFeedback = (newFeedback: Partial<FeedbackRequest>) => {
    if (feedback) {
      setFeedback({ ...feedback, ...newFeedback });
    }
  };

  const handleSubmit = (feedback: FeedbackRequest) => {
    updateFeedbackItemMutation.mutate(feedback, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["reviews"] });
        navigate("..");
        toast.success("Your feedback updated successfully!");
      }
    });
  };

  const onClose = () => navigate("..");

  if (data) {
    const { title, subtitle, ratingTitle, feedbackOptions } =
      defineFeedbackFormOptions(
        data,
        feedback,
        user.buildingName,
        hideCategory
      );

    return (
      <Modal open onClose={onClose}>
        <FeedbackForm
          title={title}
          subtitle={subtitle}
          ratingTitle={ratingTitle}
          feedback={feedback}
          feedbackOptions={feedbackOptions ?? []}
          onSetFeedback={onSetFeedback}
          onSubmit={handleSubmit}
          status={
            isLoading || updateFeedbackItemMutation.isLoading
              ? "loading"
              : "idle"
          }
        />
      </Modal>
    );
  }

  return (
    <Modal open onClose={onClose}>
      <div className="flex items-center justify-center h-64">
        <Spinner color="blue" size="xl" />
      </div>
    </Modal>
  );
}
