import { useParams, useNavigate, Navigate } from "react-router-dom";
import { Button, ButtonWrapper } from "../../components/Button";
import Layout from "../../components/Layout";
import { Spinner } from "../../components/Spinner";
import useGetService from "../../hooks/useGetService";
import { BuildingServiceType, ServiceTypeEnum } from "../../types/Building";
import { OnboardingStatusEnum } from "../../types/User";
import { InsuranceProvider } from "./insurance-marketplace";
import { usePostService, useGetStatusTypeDetails } from "./landing";
import useLivlyUser from "../../context/UserProvider";

export default function ChecklistInsurancePage() {
  const params = useParams<{ leaseId: string }>();
  const navigate = useNavigate();
  const { user } = useLivlyUser();
  const details = useGetStatusTypeDetails(OnboardingStatusEnum.insurance, user);
  const { skipOnboardingStep } = usePostService(
    user.userId,
    params.leaseId!,
    details.onboardingStatus
  );

  const service = useGetService(ServiceTypeEnum.RentersInsurance);
  const insuranceType = getInsuranceType(service);

  if (insuranceType === InsuranceType.MARKETPLACE) {
    return (
      <Navigate
        to={`/lease/${user.propertyUnitLeaseId}/checklist/${user.userId}/insurance-marketplace`}
        replace
      />
    );
  }

  if (insuranceType === InsuranceType.PROOF) {
    return (
      <Navigate
        to={`/lease/${user.propertyUnitLeaseId}/checklist/${user.userId}/insurance/upload-proof`}
        state={{ label: "Move-in Checklist", returnTo: ".." }}
        replace
      />
    );
  }

  if (insuranceType === InsuranceType.CUSTOM_LINK) {
    const metaDataString = service?.metaData ?? "";
    let providers: InsuranceProvider[] = [];

    if (metaDataString) {
      try {
        const parsedMetaData = JSON.parse(metaDataString) as {
          customLinkBrand: string;
          customLinkBrandLogo: string;
          customLinkUrl: string;
          insuranceProviderId: number;
          insuranceType: number;
          onlyDocumentUpload: boolean;
          skipUploadProof: boolean;
        };

        providers = [
          {
            id: 1,
            name: parsedMetaData.customLinkBrand,
            logoUri: parsedMetaData.customLinkBrandLogo,
            url: parsedMetaData.customLinkUrl,
            heading: parsedMetaData.customLinkBrand,
            subHeading:
              "This is your property’s preferred provider, go and get a quote!",
            skipUploadProof: parsedMetaData.skipUploadProof ?? false,
          },
        ];
      } catch (e) {
        console.log("INSURANCE SERVICE INCORRECTLY SET UP");
      }
    }

    return (
      <Navigate
        to={`/lease/${user.propertyUnitLeaseId}/checklist/${user.userId}/insurance-marketplace`}
        state={{ label: "Move-in Checklist", returnTo: "..", providers }}
        replace
      />
    );
  }

  return <Navigate to={`../insurance-quote`} replace />;
}

export enum InsuranceType {
  ASSURANT,
  PROOF,
  MARKETPLACE,
  CUSTOM_LINK,
}

function getInsuranceType(service: BuildingServiceType | undefined) {
  let insuranceType = InsuranceType.ASSURANT;
  if (service && service.enabled) {
    const { metaData } = service;

    if (metaData) {
      const parsedMetaData = JSON.parse(metaData);
      insuranceType = parsedMetaData.insuranceType;
    }
  }

  return insuranceType;
}
