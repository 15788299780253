import { useFeatureToggle } from "@/routes/features";
import useLivlyUser from "../context/UserProvider";
import { ERPType } from "../types/Building";

export default function useIsAppFolioMaintenanceProperty() {
  const { user } = useLivlyUser();
  const appfolioMaintainanceFlag = useFeatureToggle("appfolioMaintenance");

  return (
    appfolioMaintainanceFlag && user.building.erpTypeId === ERPType.AppFolio
  );
}
