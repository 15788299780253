import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment-timezone";
import axios from "axios";
import { FormEvent, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import {
  getTicketStatus,
  getTicketSubmittedAtString,
  TicketAssignee,
  getLocationName,
  defaultTimezone,
  getTicketSubmittedAtType,
  useMaintenanceSettings,
  getTicketStatusName
} from "../routes/maintenance/maintenance";
import { BaseLivlyApiResponse } from "../types/Base";
import {
  Ticket,
  TicketPhoto,
  TicketHistory,
  HistoryItemType,
  TicketState,
  TicketUser
} from "../types/Maintenance";
import { BASE_API_URL } from "../utils/constants";
import { truncate } from "../utils/truncate";
import { TicketPhotos } from "./AddTicketForm";
import Alert from "./Alert";
import { Badge } from "./Badge";
import { Button } from "./Button";
import { Toggle, textAreaClass } from "./Form";
import ImagesLightbox from "./ImagesLightbox";
import ImagesPreview from "./ImagesPreview";
import {
  trackEditPermissionToEnter,
  trackEditTicketDescription
} from "../utils/analytics";
import { Spinner } from "./Spinner";
import useTicketChannels from "../context/TicketChannelsProvider";
import Avatar from "./Avatar";
import useGetNotAppFolioMaintanceProperty from "@/hooks/useIsAppFolioMaintenanceProperty";

type PatchTicketRequest = {
  maintenanceTicketId: number;
  maintenanceCategoryId?: string;
  description?: string;
  accessInstructions?: string;
  hasPermissionToEnter?: boolean;
  leaseId?: string;
};

const patchTicket = async (ticket: PatchTicketRequest) => {
  const { data } = await axios.patch<BaseLivlyApiResponse<Ticket>>(
    `${BASE_API_URL}/livly/maintenanceTicketsResidential/${ticket.maintenanceTicketId}`,
    ticket,
    {
      headers: { "Content-Type": "application/json" }
    }
  );

  return data.Data;
};

function usePatchTicket() {
  const params = useParams<{ leaseId: string; propertyId: string }>();

  return useMutation((ticket: PatchTicketRequest) =>
    patchTicket({ ...ticket, leaseId: params.leaseId! })
  );
}

export default function ViewTicketForm({ ticket }: { ticket: Ticket }) {
  const queryClient = useQueryClient();
  const params = useParams<{ propertyId: string }>();
  const { t } = useTranslation();
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [photos, setPhotos] = useState<TicketPhoto[]>(ticket.photos ?? []);
  const { data: maintenanceSettings } = useMaintenanceSettings(
    params.propertyId!
  );
  const { messageCounts } = useTicketChannels();

  const { statusLabelKey, statusLabelVariant } = getTicketStatus(
    ticket.ticketStatus
  );

  const onUpdate = () => {
    queryClient.invalidateQueries(["maintenance"]);
  };
  const hideCategory = useGetNotAppFolioMaintanceProperty();

  return (
    <div className="flex flex-col max-w-lg mx-auto space-y-4 divide-y divide-gray-200 divide-solid">
      <div className="flex items-center justify-between">
        <div>
          <Trans
            i18nKey="tickets.mobile.submitted-at"
            values={{
              time: getTicketSubmittedAtString(ticket.submittedAt, t)
            }}
          >
            <div className="inline font-bold">Submitted</div> 12:30 PM
          </Trans>
        </div>
        <Badge type={statusLabelVariant}>{t(statusLabelKey)}</Badge>
      </div>
      <TicketAssignee assignedToUser={ticket.assignedToUser} />
      <div className="pt-4">
        {!hideCategory && (
          <h4 className="text-lg font-bold">{ticket.category.name}</h4>
        )}
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon="map-marker-alt" className="text-red-400" />
          <p>{getLocationName(ticket, true, t)}</p>
        </div>
      </div>

      <TicketDescription ticket={ticket} onUpdate={onUpdate} />

      <TicketAccessInstructions ticket={ticket} onUpdate={onUpdate} />

      {maintenanceSettings?.isChatEnabled && (
        <div className="pt-4">
          <Link to="chat" state={{ status: ticket.ticketStatus }}>
            <Button
              color="default"
              className="flex items-center justify-center w-full gap-2"
            >
              <FontAwesomeIcon icon={["fal", "comment"]} />
              {messageCounts[ticket.maintenanceTicketId] || "Chat"}
            </Button>
          </Link>
        </div>
      )}

      <PermissionToEnter ticket={ticket} onUpdate={onUpdate} />

      <div>
        <TicketPhotos
          maintenanceTicketId={ticket.maintenanceTicketId}
          photos={photos}
          setTicketPhotos={setPhotos}
          hideList
          isDisabled={[TicketState.CLOSED, TicketState.REJECTED].includes(
            ticket.ticketStatus
          )}
        />
      </div>

      {photos.length > 0 && (
        <>
          <ImagesPreview
            image={photos[0].thumbnailUri}
            count={photos.length}
            alt="Ticket Photos"
            onClick={() => setIsLightboxOpen(true)}
          />

          {isLightboxOpen && (
            <ImagesLightbox
              images={photos.map((photo) => photo.uri)}
              onClose={() => setIsLightboxOpen(false)}
            />
          )}
        </>
      )}

      <div className="pt-4">
        <p className="text-gray-500">{t("tickets.mobile.submittee")}</p>
        <div className="flex items-center mt-2">
          <Avatar
            src={ticket.submittedByUser.avatarUri}
            name={`${ticket.submittedByUser.firstName} ${ticket.submittedByUser.lastName}`}
            size="sm"
          />
          <span className="ml-2">
            {ticket.submittedByUser.firstName} {ticket.submittedByUser.lastName}
          </span>
        </div>
      </div>

      <History />
    </div>
  );
}

function PermissionToEnter({
  ticket,
  onUpdate
}: {
  ticket: Ticket;
  onUpdate: () => void;
}) {
  const [hasPermissionToEnter, setHasPermissionToEnter] = useState(
    ticket.hasPermissionToEnter ?? false
  );
  const { mutate, isLoading } = usePatchTicket();
  const { t } = useTranslation();

  const handleChange = (checked: boolean) => {
    setHasPermissionToEnter(checked);

    mutate(
      {
        maintenanceTicketId: ticket.maintenanceTicketId,
        hasPermissionToEnter: checked
      },
      {
        onSuccess: () => {
          trackEditPermissionToEnter();
          onUpdate();
        },
        onError: () => {
          setHasPermissionToEnter(!checked);
          alert("Error");
        }
      }
    );
  };

  return (
    <div className="flex justify-between pt-4">
      <div className="flex items-center gap-x-2">
        <FontAwesomeIcon icon="badge-check" className="text-blue-500" />
        <p>{t("tickets.details.permission-to-enter")}</p>
      </div>
      <div className="flex items-center gap-x-2">
        {isLoading && <Spinner color="livly" />}
        <Toggle
          checked={hasPermissionToEnter ?? false}
          onChange={handleChange}
          disabled={
            isLoading ||
            [TicketState.CLOSED, TicketState.REJECTED].includes(
              ticket.ticketStatus
            )
          }
        />
      </div>
    </div>
  );
}

function TicketDescription({
  ticket,
  onUpdate
}: {
  ticket: Ticket;
  onUpdate: () => void;
}) {
  const [description, setDescription] = useState(ticket.description);
  const [isEdit, setIsEdit] = useState(false);
  const { mutate, isLoading } = usePatchTicket();
  const { t } = useTranslation();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    const description = formData.get("description") as string;

    mutate(
      {
        maintenanceTicketId: ticket.maintenanceTicketId,
        description
      },
      {
        onError: () => {
          alert("Error");
        },
        onSuccess: () => {
          trackEditTicketDescription();
          setDescription(description);
          setIsEdit(false);
          onUpdate();
        }
      }
    );
  };

  return (
    <div className="pt-4">
      <div className="flex items-center justify-between mb-2">
        <p>{t("tickets.details.description")}</p>
        {[TicketState.NEW, TicketState.OPEN].includes(ticket.ticketStatus) ? (
          <button
            onClick={() => setIsEdit(!isEdit)}
            disabled={isLoading}
            className="text-sm text-red-300 underline"
          >
            {isEdit ? "Done" : "Edit"}
          </button>
        ) : null}
      </div>
      {isEdit ? (
        <form onSubmit={handleSubmit} className="m-1">
          <textarea
            rows={4}
            className={textAreaClass}
            defaultValue={description}
            name="description"
          />
          <div className="flex items-center justify-end mt-2 gap-x-2">
            {isLoading && <Spinner />}
            <Button
              size="xs"
              type="submit"
              className="flex items-center gap-2"
              disabled={isLoading}
            >
              {isLoading && <Spinner />}
              Update
            </Button>
          </div>
        </form>
      ) : (
        <p className="mt-2 text-sm whitespace-pre-wrap">
          {truncate(description || "", 330)}
        </p>
      )}
    </div>
  );
}

function TicketAccessInstructions({
  ticket,
  onUpdate
}: {
  ticket: Ticket;
  onUpdate: () => void;
}) {
  const [accessInstructions, setAccessInstructions] = useState(
    ticket.accessInstructions
  );
  const [isEdit, setIsEdit] = useState(false);
  const { mutate, isLoading } = usePatchTicket();
  const { t } = useTranslation();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    const accessInstructions = formData.get("accessInstructions") as string;

    mutate(
      {
        maintenanceTicketId: ticket.maintenanceTicketId,
        accessInstructions
      },
      {
        onError: () => {
          alert("Error");
        },
        onSuccess: () => {
          trackEditTicketDescription();
          setAccessInstructions(accessInstructions);
          setIsEdit(false);
          onUpdate();
        }
      }
    );
  };

  return (
    <div className="pt-4">
      <div className="flex items-center justify-between mb-2">
        <p>{t("tickets.details.access-instructions")}</p>
        {[TicketState.NEW, TicketState.OPEN].includes(ticket.ticketStatus) ? (
          <button
            onClick={() => setIsEdit(!isEdit)}
            disabled={isLoading}
            className="text-sm text-red-300 underline"
          >
            {isEdit ? "Done" : "Edit"}
          </button>
        ) : null}
      </div>
      {isEdit ? (
        <form onSubmit={handleSubmit} className="m-1">
          <textarea
            rows={4}
            className={textAreaClass}
            defaultValue={accessInstructions}
            name="accessInstructions"
          />
          <div className="flex items-center justify-end mt-2 gap-x-2">
            {isLoading && <Spinner />}
            <Button
              size="xs"
              type="submit"
              className="flex items-center gap-2"
              disabled={isLoading}
            >
              {isLoading && <Spinner />}
              Update
            </Button>
          </div>
        </form>
      ) : (
        <p className="mt-2 text-sm whitespace-pre-wrap">
          {truncate(accessInstructions || "", 330)}
        </p>
      )}
    </div>
  );
}

const validHistoryItems = [
  HistoryItemType.DescriptionChanged,
  HistoryItemType.AccessInstructionsChanged,
  HistoryItemType.StaffNotesChanged,
  HistoryItemType.TicketSource,
  HistoryItemType.Assigned,
  HistoryItemType.WatcherAdded,
  HistoryItemType.WatcherRemoved,
  HistoryItemType.CategoryChanged,
  HistoryItemType.HasPermissionToEnterChanged,
  HistoryItemType.PriorityChanged,
  HistoryItemType.UnitLocationChanged,
  HistoryItemType.CommonAreaChanged,
  HistoryItemType.PropertyUnitChanged,
  HistoryItemType.Submit,
  HistoryItemType.StatusChanged,
  HistoryItemType.AttachmentAdded
];

const getTicketHistory = async (ticketId: string) => {
  const { data } = await axios.get<BaseLivlyApiResponse<TicketHistory[]>>(
    `${BASE_API_URL}/livly/maintenanceTicketsResidential/${ticketId}/history`,
    {
      params: {
        page: 1,
        limit: 100
      }
    }
  );

  return data.Data;
};

const ticketHistoryQuery = (ticketId: string) => ({
  queryKey: ["maintenance", "ticket", "history", ticketId],
  queryFn: async () => getTicketHistory(ticketId)
});

function History() {
  const params = useParams<{ ticketId: string }>();
  const { t } = useTranslation();
  const {
    data = [],
    isLoading,
    isFetching,
    isError,
    refetch
  } = useQuery(ticketHistoryQuery(params.ticketId!));

  const ticketHistory = data.filter((item) =>
    validHistoryItems.includes(item.historyItemType)
  );

  return (
    <div className="pt-4">
      <p className="text-gray-500">{t("tickets.details.history")}</p>
      {isError ? (
        <Alert
          message="There was an issue loading history. Please try again"
          action={{ label: "Try again", onClick: refetch }}
        />
      ) : isLoading || isFetching ? (
        <div>
          <p>Loading history...</p>
        </div>
      ) : ticketHistory.length === 0 ? (
        <>{t("tickets.details.history.empty")}</>
      ) : (
        <div className="flow-root mt-4">
          <ul>
            {ticketHistory.map((event, eventIdx) => (
              <li key={event.maintenanceTicketHistoryId}>
                <div className="relative pb-8">
                  {eventIdx !== data.length - 1 ? (
                    <span
                      className="absolute top-5 left-2 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div className="relative flex w-4 h-4 bg-green-500 rounded-full ring-8 ring-white top-1" />
                    <div className="flex-1 min-w-0">
                      <div>
                        <div className="mt-0.5 text-sm text-gray-500">
                          {renderDate(event.changedAt, t)}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-sm text-gray-700">
                        {eventIdx === 0 ? (
                          <p className="font-bold text-gray-900">
                            {getTicketHistoryItemType(event, t)}
                          </p>
                        ) : event.historyItemType ===
                          HistoryItemType.TicketSource ? (
                          event.newValue
                        ) : (
                          <p className="font-bold text-gray-900">
                            {getTicketHistoryItemType(event, t)}
                          </p>
                        )}
                      </div>
                      <div className="text-sm text-gray-600">
                        {getUserName(event.changedByUserModel)}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

const getTicketHistoryItemType = (ticketHistoryItem: TicketHistory, t: any) => {
  switch (ticketHistoryItem.historyItemType) {
    case HistoryItemType.Submit:
      return "Ticket Submitted";
    case HistoryItemType.StatusChanged:
      const ticketState = Number(ticketHistoryItem.newValue) as TicketState;
      return getTicketStatusName(ticketState, t);
    case HistoryItemType.Assigned:
      if (ticketHistoryItem.newValue === "0") {
        return t("tickets.details.history.status.unassigned");
      }
      return t("tickets.details.history.status.assigned", {
        name: ticketHistoryItem.newValue
      });
    case HistoryItemType.CategoryChanged:
      return t("tickets.details.history.status.category-changed");
    case HistoryItemType.TicketSource:
      return "Ticket Source";
    case HistoryItemType.StaffNotesChanged:
      return "Staff Notes";
    case HistoryItemType.WatcherAdded:
      return "Watcher Added";
    case HistoryItemType.WatcherRemoved:
      return "Watcher Removed";
    case HistoryItemType.CommonAreaChanged:
    case HistoryItemType.PropertyUnitChanged:
    case HistoryItemType.UnitLocationChanged:
      return "Location Updated";
    case HistoryItemType.HasPermissionToEnterChanged:
      return "Permission to Enter Updated";
    case HistoryItemType.PriorityChanged:
      return "Priority Updated";
    case HistoryItemType.DescriptionChanged:
      return "Description";
    case HistoryItemType.AccessInstructionsChanged:
      return "Access Instructions";
    case HistoryItemType.AttachmentAdded:
      return "Attachment Added";
    default:
      throw new Error("Invalid ticket history item type");
  }
};

function getUserName(user: TicketUser, isShortName = false): string {
  const { firstName, lastName } = user;

  if (!lastName) {
    return firstName;
  }

  if (!isShortName) {
    return `${firstName} ${lastName}`;
  }

  return `${firstName} ${lastName[0]}.`;
}

const renderDate = (date: Date, t: any) => {
  const dateObject = getTicketSubmittedAtObject(date, t);

  return (
    <>
      {!!dateObject.primary && <p>{dateObject.primary}</p>}

      {!!dateObject.secondary && (
        <p className="text-sm text-gray-600">{dateObject.secondary}</p>
      )}
    </>
  );
};

const getTicketSubmittedAtObject = (submittedAt: Date, t: any) => {
  const date = moment.tz(submittedAt, defaultTimezone);
  const type = getTicketSubmittedAtType(date, defaultTimezone);

  switch (type) {
    case "today":
      return {
        primary: date.format("h:mm A"),
        secondary: t("tickets.date-today")
      };
    case "yesterday":
      return {
        primary: date.format("h:mm A"),
        secondary: t("tickets.date-yesterday")
      };
    case "withinWeek":
      return { primary: date.format("dddd") };
    case "greaterThanWeek":
      return { primary: date.format("M/D/YYYY") };
    default:
      throw new Error("Invalid submitted date type");
  }
};
