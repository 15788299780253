import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { InsuranceProvider } from "../routes/checklist/insurance-marketplace";
import {
  trackInsuranceMarketplaceVendorClicked,
  trackLemonadeInsuranceMarketplaceVendorClicked,
} from "../utils/analytics";
import { Button } from "./Button";
import { Spinner } from "./Spinner";

interface Props {
  isLoading: boolean;
  onSkip: () => void;
  insuranceProviders: InsuranceProvider[];
  onDone: () => Promise<void>;
}

function InsuranceMarketplace({
  isLoading,
  onSkip,
  insuranceProviders,
  onDone,
}: Props) {
  const isSkipUploadProof =
    insuranceProviders?.some(
      (provider) => provider?.skipUploadProof === true
    ) ?? false;

  const onButtonClick = (provider: InsuranceProvider) => {
    const isFromNativeApp = localStorage.getItem("mobile") === "true";

    if (provider.name === "Lemonade") {
      trackLemonadeInsuranceMarketplaceVendorClicked(provider.name);
    }

    trackInsuranceMarketplaceVendorClicked(provider.name);

    if (isFromNativeApp) {
      window.open(provider.url, "_self");
    } else {
      window.open(provider.url, "_blank");
    }
  };

  return (
    <>
      <div className="flex justify-end">
        {!isSkipUploadProof ? (
          <Button
            color="default"
            onClick={onSkip}
            className="flex items-center w-full gap-2 md:w-auto"
            disabled={isLoading}
          >
            {isLoading && <Spinner />}
            Skip
          </Button>
        ) : (
          <Button
            color="default"
            onClick={onDone}
            className="flex items-center w-full gap-2 md:w-auto"
            disabled={isLoading}
          >
            {isLoading && <Spinner />}
            Next
          </Button>
        )}
      </div>
      <h4 className="mt-4">
        Your property requires proof of insurance prior to move in. You can
        browse our list of partners below and return to upload your policy
        document after purchasing.
      </h4>
      {!isSkipUploadProof && (
        <div className="mt-4">
          <Link
            to={`../insurance/upload-proof`}
            state={{ label: "Insurance Marketplace", returnTo: -1 }}
            className="flex items-start gap-4 md:p-4 md:rounded-lg md:hover:bg-gray-50"
          >
            <FontAwesomeIcon
              icon={["far", "arrow-up-to-line"]}
              className="text-2xl text-red-400"
            />
            <div className="flex-1">
              <p className="font-medium">Upload proof</p>
              <p className="mt-1 text-sm">
                Provider proof of insurance. Once uploaded, a property manager
                will review.
              </p>
            </div>
            <FontAwesomeIcon icon="chevron-right" className="text-gray-400" />
          </Link>
        </div>
      )}
      <div className="pt-4 mt-4 border-t border-gray-200">
        {insuranceProviders.length === 0 && (
          <div className="py-10">
            <p>Sorry. There are currently no insurance providers available.</p>
          </div>
        )}
        {insuranceProviders.length > 0 && (
          <div className="flex flex-wrap gap-4">
            {insuranceProviders.map((provider) => (
              <div
                key={provider.id}
                className="relative w-full p-6 text-sm border border-gray-200 rounded-lg shadow-md md:max-w-xs"
              >
                {provider.logoUri && (
                  <img
                    src={provider.logoUri}
                    alt={provider.name}
                    className="max-h-10"
                  />
                )}
                <div className={classNames({ "mt-4": provider.logoUri })}>
                  <p className="font-medium">{provider.heading}</p>
                  <p className="mt-1">{provider.subHeading}</p>
                </div>
                <div className="mt-4">
                  <Button
                    className="w-full"
                    onClick={() => onButtonClick(provider)}
                  >
                    Browse
                  </Button>
                </div>
                <div className="absolute bottom-0 left-0 right-0 h-1 bg-blue-600 rounded-bl-lg rounded-br-lg"></div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export { InsuranceMarketplace };
