import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import Alert from "../../components/Alert";
import { Badge, BadgeProps } from "../../components/Badge";
import { Button } from "../../components/Button";
import Layout from "../../components/Layout";
import { BaseLivlyApiResponse } from "../../types/Base";
import { ServiceProviderType, ServiceTypeEnum } from "../../types/Building";
import {
  Ticket,
  TicketCounter,
  TicketState,
  TicketUser
} from "../../types/Maintenance";
import { BASE_API_URL } from "../../utils/constants";
import { truncate } from "../../utils/truncate";
import {
  trackViewMaintenancePage,
  trackViewMaintenanceTickets,
  trackViewTicketDetails
} from "../../utils/analytics";
import { Spinner } from "../../components/Spinner";
import useLivlyUser from "../../context/UserProvider";
import classNames from "classnames";
import Counter from "../../components/Counter";
import useTicketChannels from "../../context/TicketChannelsProvider";
import useGetNotAppFolioMaintanceProperty from "@/hooks/useIsAppFolioMaintenanceProperty";

export const TICKETS_PAGE_SIZE = 10;

export interface MaintenanceSettings {
  propertyConfigurationId: number;
  propertyId: number;
  isChatEnabled: boolean;
  modifiedDate: string | Date;
  modifiedByUserId: number;
  modifiedByFirstName: string;
  modifiedByLastName: string;
  hasPermissionToEnter: boolean;
}

async function getMaintenanceSettings(propertyId: string) {
  const result = await axios.get<BaseLivlyApiResponse<MaintenanceSettings>>(
    `${BASE_API_URL}/livly/maintenanceTickets/${propertyId}/settings`
  );

  return result.data.Data;
}

const maintenanceSettingsQuery = (propertyId: string) => ({
  queryKey: ["maintenance", "settings", propertyId],
  queryFn: async () => getMaintenanceSettings(propertyId)
});

export function useMaintenanceSettings(propertyId: string) {
  return useQuery({
    ...maintenanceSettingsQuery(propertyId)
  });
}

export default function MaintenancePage() {
  const { user } = useLivlyUser();
  const { building } = user;
  const externalMaintenanceService = building.serviceTypes?.find(
    (serviceType: any) =>
      serviceType.serviceType === ServiceTypeEnum.Maintenance &&
      serviceType.serviceProviderType === ServiceProviderType.External
  );

  useEffect(() => {
    trackViewMaintenancePage();
  }, []);

  if (externalMaintenanceService) {
    return (
      <Layout title="Maintenance">
        <Button color="secondary" size="small">
          <a
            href={externalMaintenanceService.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click Here for Maintenance
          </a>
        </Button>
      </Layout>
    );
  }

  return <MaintenanceTicketsList />;
}

const getTicketsCounter = async (leaseId: string) => {
  const { data } = await axios.get<BaseLivlyApiResponse<TicketCounter[]>>(
    `${BASE_API_URL}/livly/maintenanceTicketsResidential/ticketCounters`,
    {
      params: {
        leaseId
      }
    }
  );

  return data.Data;
};

const ticketsCounterQuery = (leaseId: string) => ({
  queryKey: ["maintenance", "tickets", "counter", leaseId],
  queryFn: async () => getTicketsCounter(leaseId)
});

function MaintenanceTicketsList() {
  const { user } = useLivlyUser();
  const isAiChatEnabled = useGetMaintenanceAiServiceEnabled();

  const analyticsRef = useRef(false);
  const params = useParams<{ leaseId: string; propertyId: string }>();
  const locationState = useLocation().state as { status: TicketState };
  const status = locationState?.status ?? TicketState.OPEN;
  const [statusFilter, setStatusFilter] = useState(status);
  const { data: maintenanceSettings } = useMaintenanceSettings(
    params.propertyId!
  );
  const isChatEnabled = maintenanceSettings?.isChatEnabled ?? false;
  const { messageCounts } = useTicketChannels();

  const getTickets = async ({ pageParam = 1 }) => {
    let statuses: TicketState[] = [];

    switch (statusFilter) {
      case TicketState.OPEN:
        statuses = [TicketState.NEW, TicketState.OPEN];
        break;
      case TicketState.CLOSED:
        statuses = [TicketState.CLOSED, TicketState.REJECTED];
        break;
      default:
        throw new Error("Unsupported status is passed");
    }

    const { data } = await axios.get<BaseLivlyApiResponse<Ticket[]>>(
      `${BASE_API_URL}/livly/maintenanceTicketsResidential`,
      {
        params: {
          StatusIds: statuses,
          Page: pageParam,
          leaseId: params.leaseId!,
          Limit: TICKETS_PAGE_SIZE
        }
      }
    );

    if (analyticsRef.current === false) {
      trackViewMaintenanceTickets(
        statusFilter,
        data.Meta?.pagination?.count ?? 0
      );
      analyticsRef.current = true;
    }

    return {
      data: data.Data,
      nextPage: data.Meta.pagination?.has_next_page
        ? data.Meta.pagination?.page + 1
        : null
    };
  };

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch
  } = useInfiniteQuery({
    queryKey: ["maintenance", "tickets", params.leaseId, statusFilter],
    queryFn: getTickets,
    getNextPageParam: (lastPage, page) => lastPage.nextPage
  });

  const { data: ticketsCounter } = useQuery(
    ticketsCounterQuery(params.leaseId!)
  );
  const isResidentFeedbackServiceEnabled =
    (user.building.serviceTypes?.find((service) => service.serviceType === 33)
      ?.enabled ??
      false) &&
    !["Non-Resident", "Delegated"].includes(user.userType);

  const getTicketStateCount = (state: "Open" | "Closed") => {
    let ticketStates: Array<TicketState> = [];

    if (state === "Open") {
      ticketStates = [TicketState.NEW, TicketState.OPEN];
    } else {
      ticketStates = [TicketState.CLOSED, TicketState.REJECTED];
    }

    return ticketsCounter
      ?.filter((tc) => ticketStates.includes(tc.statusId))
      .reduce((prev, curr) => (prev += curr.count), 0);
  };

  return (
    <Layout title="Maintenance">
      <div className="max-w-lg mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex gap-10">
            {[
              ["Open", TicketState.OPEN, getTicketStateCount("Open")],
              ["Closed", TicketState.CLOSED, getTicketStateCount("Closed")]
            ].map(([label, ticketState, ticketCount]) => (
              <button
                key={label}
                className={`flex-1 flex justify-center md:flex-none relative ounded-lg px-3 py-2 text-gray-700 text-sm font-medium transition-colors delay-150`}
                onClick={() => setStatusFilter(ticketState as TicketState)}
              >
                {ticketState === statusFilter && (
                  <motion.span
                    className="absolute inset-0 rounded-lg bg-blue-50"
                    layoutId="hoverBackground"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.15 } }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 0.15, delay: 0.2 }
                    }}
                  />
                )}
                <div className="flex gap-1">
                  <span className={`relative z-[1]`}>{label}</span>
                  <span className="text-blue-600 relative z-[1]">
                    {ticketCount}
                  </span>
                </div>
              </button>
            ))}
          </div>
          <Link
            to={isAiChatEnabled ? "ai" : "add-ticket"}
            className="hidden md:block"
          >
            <Button className="hidden md:block" size="small">
              Create ticket
            </Button>
          </Link>
        </div>
        {isError ? (
          <Alert
            message="There was an error loading tickets. Please try again"
            action={{ label: "Try again", onClick: refetch }}
          />
        ) : isLoading ? (
          <div className="flex items-center justify-center flex-1 my-56">
            <Spinner color="livly" size="xl" />
          </div>
        ) : data.pages[0].data.length === 0 ? (
          <div className="my-10 text-center">
            <h4 className="text-xl">No maintenance tickets yet</h4>
            <p className="mt-2 text-sm font-light">
              Any tickets you create will appear here
            </p>
          </div>
        ) : (
          <div className="mt-6">
            <ul className="flex flex-col space-y-4">
              {data.pages.map((group, i) => (
                <React.Fragment key={i}>
                  {group.data.map((ticket) => (
                    <TicketListItem
                      key={ticket.maintenanceTicketId}
                      ticket={ticket}
                      ticketChatCount={
                        messageCounts[ticket.maintenanceTicketId] ?? 0
                      }
                      isChatEnabled={isChatEnabled}
                      statusFilter={statusFilter}
                      isResidentFeedbackServiceEnabled={
                        isResidentFeedbackServiceEnabled
                      }
                    />
                  ))}
                </React.Fragment>
              ))}
            </ul>
            {hasNextPage && (
              <div className="mt-4">
                <Button
                  color="default"
                  className="w-full"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage
                    ? "Loading more..."
                    : hasNextPage
                    ? "Load More"
                    : "Nothing more to load"}
                </Button>
              </div>
            )}
            <div className="mt-4 text-center">
              {isFetching && !isFetchingNextPage ? "Loading tickets..." : null}
            </div>
          </div>
        )}
      </div>
      <Link to={isAiChatEnabled ? "ai" : "m/add-ticket"}>
        <button className="fixed z-20 w-16 h-16 text-white bg-blue-600 rounded-full bottom-6 right-6 md:hidden">
          <FontAwesomeIcon icon="plus" className="text-xl" />
        </button>
      </Link>
      <Outlet />
    </Layout>
  );
}

function TicketListItem({
  ticket,
  ticketChatCount,
  isChatEnabled,
  isResidentFeedbackServiceEnabled,
  statusFilter
}: {
  ticket: Ticket;
  ticketChatCount: number;
  isChatEnabled: boolean;
  isResidentFeedbackServiceEnabled: boolean;
  statusFilter: any;
}) {
  const { t } = useTranslation();
  const ticketDateString = getTicketSubmittedAtString(ticket.submittedAt, t);
  const { statusLabelKey, statusLabelVariant } = getTicketStatus(
    ticket.ticketStatus
  );

  const isRateExperienceEnabled =
    isResidentFeedbackServiceEnabled && ticket.ResidentFeedbackId !== null;
  const hideCategory = useGetNotAppFolioMaintanceProperty();

  return (
    <li className="p-4 rounded-lg shadow">
      <div className="flex justify-between">
        <div>
          <div>
            <p className="text-sm text-gray-500">{ticketDateString}</p>
          </div>
          {!hideCategory && (
            <div>
              <p className="text-lg font-medium">{ticket.category.name}</p>
            </div>
          )}
          <div className="flex items-center">
            <p>{getLocationName(ticket, true, t)}</p>
            {ticket.hasPermissionToEnter && (
              <FontAwesomeIcon
                icon="badge-check"
                className="ml-2 text-blue-500"
              />
            )}
          </div>
        </div>
        {isChatEnabled && (
          <div>
            <span
              className={classNames(
                "gap-2 px-3 py-1 text-sm border border-gray-200 rounded-md flex items-center animate duration-200",
                { "bg-red-100 text-red-500": ticketChatCount > 0 }
              )}
            >
              <FontAwesomeIcon
                icon={[ticketChatCount > 0 ? "fas" : "fal", "comment"]}
                className={classNames("animate duration-200")}
              />
              <Counter value={ticketChatCount} />
            </span>
          </div>
        )}
      </div>

      {ticket.description && (
        <div className="mt-4 text-sm">
          <p className="whitespace-pre-wrap">
            {truncate(ticket.description || "", 90)}
          </p>
        </div>
      )}

      <div className="pt-4 mt-4 border-t border-gray-200">
        <div className="flex justify-between">
          <div className="flex-1">
            <p>Assignee</p>
            <TicketAssignee assignedToUser={ticket.assignedToUser} />
          </div>
          <div className="flex-1">
            <p>Status</p>
            <Badge type={statusLabelVariant} className="mt-4">
              {t(statusLabelKey)}
            </Badge>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4 pt-4 mt-4 border-t border-gray-200">
        {isRateExperienceEnabled && (
          <Link
            to={`${ticket.maintenanceTicketId}/rate-experience`}
            state={{
              feedbackId: ticket.ResidentFeedbackId
            }}
          >
            <Button color="default" size="small" className="w-full">
              {t("tickets.details.rate-experience")}
            </Button>
          </Link>
        )}
        <Link
          className="w-full md:hidden"
          to={`m/${ticket.maintenanceTicketId.toString()}`}
          state={{
            status: statusFilter,
            navigatingTicketId: ticket.maintenanceTicketId
          }}
        >
          <button
            color="default"
            className="flex items-center justify-center w-full gap-2 text-red-300 underline"
            onClick={() => trackViewTicketDetails("Ticket list")}
          >
            {t("tickets.details.view-details")}
          </button>
        </Link>
        <Link
          className="hidden w-full md:block"
          to={ticket.maintenanceTicketId.toString()}
          state={{
            status: statusFilter,
            navigatingTicketId: ticket.maintenanceTicketId
          }}
        >
          <button
            color="default"
            className="flex items-center justify-center w-full gap-2 text-red-300 underline"
            onClick={() => trackViewTicketDetails("Ticket list")}
          >
            {t("tickets.details.view-details")}
          </button>
        </Link>
      </div>
    </li>
  );
}

// Use "Central Standard Time" by default to calculate relative date string
// (yesterday/day of week) because backend, for now, returns dates only in CST.
// When other clients are on-boarded need to handle timezone (on backend and frontend)
export const defaultTimezone = "America/Chicago";

export function getTicketStatus(ticketStatus: TicketState) {
  let statusLabelKey = "";
  let statusLabelVariant: BadgeProps = {};

  switch (ticketStatus) {
    case TicketState.NEW:
      statusLabelKey = "tickets.status-new";
      statusLabelVariant.type = "info";
      break;
    case TicketState.OPEN:
      statusLabelKey = "tickets.status-open";
      statusLabelVariant.type = "default";
      break;
    case TicketState.CLOSED:
      statusLabelKey = "tickets.status-closed";
      statusLabelVariant.type = "success";
      break;
    case TicketState.REJECTED:
      statusLabelKey = "tickets.status-rejected";
      statusLabelVariant.type = "danger";
      break;
  }

  return {
    statusLabelKey: statusLabelKey,
    statusLabelVariant: statusLabelVariant.type
  };
}

export function getTicketStatusName(status: TicketState, t: any): string {
  const ticketStatus = getTicketStatus(status);

  return t(ticketStatus.statusLabelKey);
}

export function getLocationName(
  ticket: Ticket,
  isFull: boolean = false,
  t?: any
): string {
  if (ticket.propertyUnit) {
    const unitNumber = ticket.propertyUnit.unitNumber;

    if (!isFull || !t) {
      return unitNumber;
    }

    return t("tickets.details.unit", { unit: unitNumber });
  }

  if (ticket.commonSpaceArea) {
    return ticket.commonSpaceArea.name;
  }

  return "";
}

type SubmittedAtType = "today" | "yesterday" | "withinWeek" | "greaterThanWeek";

export const getTicketSubmittedAtType = (
  submittedDate: moment.Moment,
  timezone: string
): SubmittedAtType => {
  const today = moment().tz(timezone);

  const startOfDay = today.clone().startOf("day");
  const isThisDay = submittedDate.isAfter(startOfDay);

  if (isThisDay) {
    return "today";
  }

  const yesterday = today.clone().subtract(1, "days").startOf("day");
  const isYesterday = submittedDate.isSame(yesterday, "d");

  if (isYesterday) {
    return "yesterday";
  }

  const weekOld = today.clone().subtract(7, "days").startOf("day");
  const isWithinAWeek = submittedDate.isAfter(weekOld);

  if (isWithinAWeek) {
    return "withinWeek";
  }

  return "greaterThanWeek";
};

export const getTicketSubmittedAtString = (submittedAt: Date, t: any) => {
  const date = moment.tz(submittedAt, defaultTimezone);
  const type = getTicketSubmittedAtType(date, defaultTimezone);

  switch (type) {
    case "today":
      return date.format("h:mm A");
    case "yesterday":
      return t("tickets.date-yesterday");
    case "withinWeek":
      return date.format("dddd");
    case "greaterThanWeek":
      return date.format("M/D/YYYY");
    default:
      throw new Error("Invalid submitted date type");
  }
};

export function TicketAssignee({
  assignedToUser
}: {
  assignedToUser: TicketUser | null;
}) {
  const { t } = useTranslation();

  if (assignedToUser) {
    return (
      <div className="flex items-center mt-2">
        <img
          src={assignedToUser.avatarUri}
          className="object-cover w-10 h-10 rounded-full"
        />
        <span className="ml-2">
          {assignedToUser.firstName} {assignedToUser.lastName}
        </span>
      </div>
    );
  }

  return (
    <div className="flex items-center pt-4">
      <div className="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full">
        <FontAwesomeIcon icon="user" className="text-lg text-blue-500" />
      </div>
      <span className="ml-2">{t("tickets.details.unassigned")}</span>
    </div>
  );
}

function useGetMaintenanceAiServiceEnabled() {
  const { user } = useLivlyUser();
  const maintenanceService = user.building.serviceTypes?.find(
    (serviceType: any) =>
      serviceType.serviceType === ServiceTypeEnum.Maintenance
  );

  if (!maintenanceService) {
    return false;
  }

  let isAiChatEnabled = false;
  const { metaData } = maintenanceService;

  if (metaData) {
    const parsedMetaData = JSON.parse(metaData);
    isAiChatEnabled = parsedMetaData.aiChatEnabled;
  }

  return isAiChatEnabled;
}
